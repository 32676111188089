<template>
  <q-form ref="editForm">
    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="교육대상자 정보" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-detail">
          <q-chip
            v-for="(item, idx) in items"
            :key="idx"
            :color="customColor(item, item.attendeesTypeCd)"
            text-color="white"
            icon="person_outline"
            :label="item.attendeesPositionName"
            :title="item.attendeesPositionName"
          >
          </q-chip>
        </template>
      </c-card>
    </div> -->
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="부서/직책/법규선해임 교육대상자"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :filtering="false"
        :checkClickFlag="false"
        :columnSetting="false"
        :merge="grid.merge"
        :usePaging="false"
        :hideBottom="true"
        noDataLabel="교육대상자가 없습니다."
        :editable="editable && !disabled"
        rowKey="attendeesNo"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem" />
            <c-btn
              v-if="editable && !disabled && grid.data.length > 0"
              :url="insertUrl"
              :isSubmit="isSave1"
              :param="grid.data"
              mappingType="POST"
              label="저장"
              icon="save"
              @beforeAction="saveEducation1"
              @btnCallback="saveCallback1" 
            />
            <c-btn v-if="editable && !disabled && grid.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'click'">
            <q-btn 
              round unelevated 
              size="10px"
              color="amber" 
              icon="search"
              @click.stop="() => { rowIndex = props.rowIndex, electronSignature = props.row.electronSignature}"
              >
              <q-popup-proxy :ref="'proxy1_' + props.rowIndex" :breakpoint="400">
                <component
                  :is="col.component"
                  :popupParam="props.row"
                  :rowIndex="props.rowIndex"
                  @callback="callback1"
                />
              </q-popup-proxy>
            </q-btn>
          </template>
          <!-- <template v-if="col.name === 'electronSignature'">
            <div class="col-12">
              <q-img :src="props.row.electronSignature" fit="scale-down"
                :ratio="4/3"
                style="min-width: 100px; max-width: 150px">
                <template v-slot:loading>
                  <q-spinner-gears color="white" />
                </template>
              </q-img>
            </div>
          </template> -->
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table4"
        title="외부인력 교육대상자"
        :columns="grid2.columns"
        :gridHeight="grid2.height"
        :data="grid2.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :checkClickFlag="false"
        :hideBottom="true"
        noDataLabel="교육대상자가 없습니다."
        :editable="editable && !disabled"
        rowKey="attendeesNo"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem4" />
            <c-btn
              v-if="editable && !disabled && grid2.data.length > 0"
              :url="insertUrl"
              :isSubmit="isSave2"
              :param="grid2.data"
              mappingType="POST"
              label="저장"
              icon="save"
              @beforeAction="saveEducation2"
              @btnCallback="saveCallback2" 
            />
            <c-btn v-if="editable && !disabled && grid2.data.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem4" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'click'">
            <q-btn 
              round unelevated 
              size="10px"
              color="amber" 
              icon="mode"
              @click.stop="() => { rowIndex = props.rowIndex, electronSignature = props.row.electronSignature}"
              >
              <q-popup-proxy :ref="'proxy2_' + props.rowIndex" :breakpoint="400">
                <component
                  :is="col.component"
                  :outFlag="true"
                  :popupParam="props.row"
                  :tabParam="tabParam"
                  :rowIndex="props.rowIndex"
                  @callback="callback2"
                />
              </q-popup-proxy>
            </q-btn>
          </template>
          <!-- <template v-if="col.name === 'electronSignature'">
            <div class="col-12">
              <q-img :src="props.row.electronSignature" fit="scale-down"
                :ratio="4/3"
                style="min-width: 100px; max-width: 150px">
                <template v-slot:loading>
                  <q-spinner-gears color="white" />
                </template>
              </q-img>
            </div>
          </template> -->
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-card title="이수자 명단 파일" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-sm-12 col-md-12 col-lg-12" style="height: 220px">
            <c-upload 
              :attachInfo="attachInfo"
              :editable="editable"
              label="이수자 명단 파일">
            </c-upload>
          </div>
        </template>
      </c-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-complete',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        eduCourseId: '',
        documentStatusCd: '',
        eduAttendeeList: [],
      }),
    },
    
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EDU_COMPLETE_LIST',
        taskKey: '',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'attendeesDeptName',
            field: 'attendeesDeptName',
            style: 'width:80px',
            label: '부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            style: 'width:80px',
            label: '직책',
            align: 'center',
            sortable: false,
          },
          // {
          //  name: 'attendeesPositionName',
          //   field: 'attendeesPositionName',
          //   innerBtn: true,
          //   btns: [
          //     { label: '', icon: 'add', color: 'orange', tooltip: '대상자 추가' },
          //   ],
          //   style: 'width:150px',
          //   label: '부서/직책/법규선해임',
          //   align: 'center',
          //   sortable: false,
          // },
          {
            name: 'attendeesName',
            field: 'attendeesName',
            label: '대상자명',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          // {
          //   name: 'checkCompleteFlagName',
          //   field: 'checkCompleteFlagName',
          //   label: '이수여부',
          //   style: 'width:100px',
          //   align: 'center',
          //   sortable: false
          // },
          {
            name: 'checkCompleteFlag',
            field: 'checkCompleteFlag',
            label: '이수여부',
            type: 'select',
            setHeader: true,
            style: 'width:100px',
            align: 'center',
            comboItems: [
              { code: 'Y', codeName: '이수' },
              { code: 'N', codeName: '미이수' },
            ],
            sortable: false,
          },
          {
            name: 'click',
            field: 'click',
            label: '서명',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
            component: () => import(`${'./signaturePop.vue'}`)
          },
          {
            name: 'electronSignatureFlag',
            field: 'electronSignatureFlag',
            label: '서명여부',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
          // {
          //   name: 'electronSignature',
          //   field: 'electronSignature',
          //   label: '전자서명',
          //   style: 'width:150px',
          //   type: 'custom',
          //   align: 'center',
          //   sortable: false,
          // },
          {
            name: 'evaluationPoint',
            field: 'evaluationPoint',
            label: '평가점수',
            type: 'number',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'evaluationContent',
            field: 'evaluationContent',
            label: '평가내용',
            type: 'text',
            style: 'width:220px',
            align: 'center',
            sortable: false
          },
          {
            name: 'evaluationDate',
            field: 'evaluationDate',
            setHeader: true,
            label: '평가일',
            type: 'date',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'attendanceTime',
            field: 'attendanceTime',
            setHeader: true,
            label: '총 교육시간',
            style: 'width:100px',
            type: 'datetime',
            align: 'center',
            sortable: false
          },
          {
            name: 'noAttendanceReason',
            field: 'noAttendanceReason',
            label: '불참사유',
            type: 'text',
            style: 'width:260px',
            align: 'left',
            sortable: false
          },
          {
            name: 'opinionContent',
            field: 'opinionContent',
            label: '의견',
            style: 'width:200px',
            type: 'text',
            align: 'left',
            sortable: false
          },
        ],
        data: [],
        height: '350px'
      },
      grid2: {
        columns: [
          {
            name: 'companyName',
            field: 'companyName',
            label: '소속회사',
            align: 'center',
            style: 'width:130px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'attendeesPositionName',
            field: 'attendeesPositionName',
            style: 'width:100px',
            label: '직책',
            type: 'text',
            align: 'center',
            sortable: false,
          },
          {
            name: 'attendeesName',
            field: 'attendeesName',
            label: '대상자명',
            type: 'text',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'residentNo',
            field: 'residentNo',
            label: '생년월일',
            type: 'date',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'inOutsideTypeCd',
            field: 'inOutsideTypeCd',
            setHeader: true,
            label: '이수여부',
            type: 'select',
            style: 'width:100px',
            align: 'center',
            comboItems: [
              { code: 'Y', codeName: '이수' },
              { code: 'N', codeName: '미이수' },
            ],
            sortable: false,
          },
          // {
          //   name: 'click',
          //   field: 'click',
          //   label: '서명',
          //   style: 'width:60px',
          //   type: 'custom',
          //   align: 'center',
          //   sortable: false,
          //   component: () => import(`${'./signaturePop.vue'}`)
          // },
          // {
          //   name: 'electronSignatureFlag',
          //   field: 'electronSignatureFlag',
          //   label: '서명여부',
          //   style: 'width:80px',
          //   align: 'center',
          //   sortable: false,
          // },
          {
            name: 'evaluationPoint',
            field: 'evaluationPoint',
            label: '평가점수',
            type: 'number',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'evaluationContent',
            field: 'evaluationContent',
            label: '평가내용',
            type: 'text',
            style: 'width:230px',
            align: 'center',
            sortable: false
          },
          {
            name: 'evaluationDate',
            field: 'evaluationDate',
            setHeader: true,
            label: '평가일',
            type: 'date',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'attendanceTime',
            field: 'attendanceTime',
            label: '총 교육시간',
            setHeader: true,
            type: 'datetime',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'noAttendanceReason',
            field: 'noAttendanceReason',
            label: '불참사유',
            type: 'text',
            style: 'width:240px',
            align: 'center',
            sortable: false
          },
          {
            name: 'opinionContent',
            field: 'opinionContent',
            label: '의견',
            style: 'width:200px',
            align: 'left',
            type: 'text',
            sortable: false
          },
        ],
        data: [],
        height: '330px'
      },
      editable: true,
      saveType: 'POST',
      isSave1: false,
      isSave2: false,
      updateUrl: '',
      insertUrl: '',
      deleteUrl: '',
      searchUrl: '',
      items: [],
      getUrl: '',
      rowIndex: 0,
      electronSignature: '',
    };
  },
  computed: {
    disabled() {
      return Boolean(this.tabParam.documentStatusCd) && this.tabParam.documentStatusCd === 'ESC000010'
    },
  },
  watch: {
    'grid.data'() {
      this.tabParam.eduAttendeeList = (this.$_.concat(this.grid.data, this.grid2.data));
    },
    'grid2.data'() {
      this.tabParam.eduAttendeeList = (this.$_.concat(this.grid.data, this.grid2.data));
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.getUrl = selectConfig.sop.edu.attendee.get.url;
      this.insertUrl = transactionConfig.sop.edu.plan.insert.targetUser.url;
      this.deleteUrl = transactionConfig.sop.edu.plan.delete.targetUser.url;
      this.getAttendUrl = selectConfig.sop.edu.result.get.targetUser.url;
      // url setting
      // code setting
      this.getDetail();
      // list setting
    },
    getDetail() {
      this.$set(this.attachInfo, 'taskKey', this.tabParam.eduEducationId)
      if (this.tabParam.eduEducationId) {
        this.grid.data = [];
        this.grid2.data = [];
        this.$_.forEach(this.tabParam.eduAttendeeList, _item => {
          if (_item.attendeesTypeCd === 'EATC00004') {
            this.grid2.data.push(_item) // 외부인력
          } else {
            this.grid.data.push(_item) // 선임
          }
        })
      }
      if (this.tabParam.eduCourseId) {
        this.$http.url = this.$format(this.getUrl, this.tabParam.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.items = _result.data
          this.updateMode = true;
        },);
      }
    },
    getAttendeeList() {
      if (this.tabParam.eduEducationId) {
        this.$http.url = this.$format(this.getAttendUrl, this.tabParam.eduEducationId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.items = _result.data
          this.updateMode = true;
        },);
      }
    },
    saveEducation1() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.grid.data, item => {
            item.regUserId = this.$store.getters.user.userId
            item.chgUserId = this.$store.getters.user.userId
          })
          
          this.isSave1 = !this.isSave1;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback1() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$_.forEach(this.grid.data, item => {
        item.editFlag = 'U'
        if (item.electronSignature) item.electronSignatureFlag = '서명'
        else item.electronSignatureFlag = '미서명'
      })
      this.getAttendeeList();
    },
    saveEducation2() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.grid2.data, item => {
            item.regUserId = this.$store.getters.user.userId
            item.chgUserId = this.$store.getters.user.userId
          })
          
          this.isSave2 = !this.isSave2;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback2() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$_.forEach(this.grid2.data, item => {
        item.editFlag = 'U'
        if (item.electronSignature) item.electronSignatureFlag = '서명'
        else item.electronSignatureFlag = '미서명'
      })
      this.getAttendeeList();
    },
    addItem() {
      this.popupOptions.title = "내부대상자 검색"; // 내부대상자 검색
      this.popupOptions.param = {
        type: 'multiple',
        isUserAll: true,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`)
      this.popupOptions.visible = true;
      this.popupOptions.width = '50%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeTargetUserPopup;
    },
    closeTargetUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.grid.data, {
            attendeesId: _item.userId,
          });
          if (index === -1) {
            this.grid.data.splice(0, 0, {
              eduCourseId: this.tabParam.eduCourseId,
              attendeesNo: uuidv4(),
              attendeesTypeCd: 'EATC00003',
              attendeesTypeName: '',
              attendeesDeptName: _item.deptName,
              attendeesPositionName: _item.jobName,
              attendeesId: _item.userId,
              attendeesName: _item.userName,
              companyName: '',
              residentNo: '',
              eduEducationId: this.tabParam.eduEducationId,
              seniorityName: _item.seniorName,
              checkCompleteFlag: null,
              electronSignature: '',
              inOutsideTypeCd: null,
              evaluationPoint: '',
              evaluationContent: '',
              evaluationDate: '',
              attendanceTime: '',
              noAttendanceReason: '',
              checkCompleteFlagName: '미이수',
              electronSignatureFlag: '미서명',
              editFlag: 'C',
            })
          }
        })
      }
    },  
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
         window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, { attendeesNo: item.attendeesNo })
              })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addItem4() {
      this.grid2.data.splice(0, 0, {
        eduCourseId: this.tabParam.eduCourseId,
        attendeesNo: uuidv4(),
        attendeesTypeCd: 'EATC00004',
        attendeesTypeName: '',
        attendeesPositionName: '',
        companyName: '',
        residentNo: '',
        eduEducationId: this.tabParam.eduEducationId,
        attendeesId: '',
        attendeesName: '',
        seniorityName: '',
        checkCompleteFlag: null,
        electronSignature: '',
        electronSignatureFlag: '미서명',
        inOutsideTypeCd: null,
        evaluationPoint: '',
        evaluationContent: '',
        evaluationDate: '',
        attendanceTime: '',
        noAttendanceReason: '',
        editFlag: 'C',
      })
    },
    removeItem4() {
      let selectData = this.$refs['table4'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, { attendeesNo: item.attendeesNo })
              })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
    },
    callback1(data, rowIndex) {
      let refName = 'proxy1_' + rowIndex;
      this.$refs[refName].hide();
    },
    callback2(data, rowIndex) {
      let refName = 'proxy2_' + rowIndex;
      this.$refs[refName].hide();
    },
    innerBtnClicked4(col, props) {
      this.grid2.data.splice(props.rowIndex, 0, {
        key: props.row.key, 
        eduCourseId: this.tabParam.eduCourseId,
        attendeesNo: uuidv4(),
        attendeesTypeCd: 'EATC00004',
        attendeesPositionName: props.row.attendeesPositionName,
        attendeesId: '',
        attendeesName: '',
        companyName: props.row.companyName,
        residentNo: props.row.residentNo,
        eduEducationId: '',
        seniorityName: '',
        checkCompleteFlag: null,
        electronSignature: '',
        inOutsideTypeCd: null,
        evaluationPoint: '',
        evaluationContent: '',
        evaluationDate: '',
        attendanceTime: '',
        noAttendanceReason: '',
        editFlag: 'C',
      });
    },
    customColor(data, type) {
      if (type === 'EATC00001') {
        return 'primary';
      } else if (type === 'EATC00002') {
        return 'red';
      } else if (type === 'EATC00003') {
        return 'orange';
      } else {
        return 'green';
      }
    }
  }
};
</script>
